<template>
	<div class="contents animate__animated animate__fadeInLeft">
		<a-spin tip="正在加载" :spinning="loading" :delay="300">
			<div style="display: flex;margin-top: 24px;">
				<a-input placeholder="请输入项目名称" v-model="searchValue" style="width: 164px;height: 32px;margin-left: 16px;"
					allowClear></a-input>
				<div class="btn">
					<img src="../../assets/serachs.png">
				</div>
				<a-tooltip title="新建项目">
					<div class="btn" @click="addProjectButtonClick">
						<img src="../../assets/file.png">
					</div>
				</a-tooltip>
			</div>
			<div class="items">
				<div
					style="font-size: 16px;color: rgba(0, 0, 0, 0.85);font-weight: 550;text-align: left;margin-left: 16px;">
					我负责的
				</div>
				<div v-if="createdProjectList.length > 0" class="items_msg">
					<!-- <div :class="itemKey == item.projectId ? 'msg selected' : 'msg'"
						v-for="(item, index) in createdProjectList" @click="handleProjectClick(item)"
						:key="item.projectId">
						<div class="project-icon-ctn" :style="{ background: colors[index % 4] }">
							<svg-icon name="group" class="project-icon"></svg-icon>
						</div>
						<div style="font-size: 14px;color: rgba(0, 0, 0, 0.85); word-break: break-all;text-align: left; ">{{ item.name }} ({{ item.code }})</div>
					</div> -->
					<ProjectListItem :itemKey="itemKey" v-for="(item, index) in createdProjectList" :item="item"
						:key="item.projectId" :index="index" style="margin-left: 16px;"
						@onClick="handleProjectClick(item)"
						@onDelete="handleProjectDelete(item)"
						@onEdit="newItem => handleProjectEditted(newItem, item)" >

					</ProjectListItem>
				</div>
				<a-empty description="暂无数据" v-else />
			</div>
			<div class="items">
				<div
					style="font-size: 16px;color: rgba(0, 0, 0, 0.85);font-weight: 550;text-align: left;margin-left: 16px;">
					我参与的
				</div>
				<div v-if="attendProjectList.length > 0" class="items_msg">
					<!-- <div :class="itemKey == item.projectId ? 'msg selected' : 'msg'"
						v-for="(item, index) in attendProjectList" @click="handleProjectClick(item)"
						:key="item.projectId">
						<div class="project-icon-ctn" :style="{ background: colors[index % 4] }">
							<svg-icon name="group" class="project-icon"></svg-icon>
						</div>
						<div style="font-size: 14px;color: rgba(0, 0, 0, 0.85);  word-break: break-all;text-align: left; ">{{ item.name }} ({{ item.code }})</div>
					</div> -->
					<ProjectListItem :itemKey="itemKey" v-for="(item, index) in  attendProjectList" :item="item"
						:key="item.projectId" :index="index" style="margin-left: 16px;" @onClick="handleProjectClick(item)">

					</ProjectListItem>
				</div>
				<a-empty description="暂无数据" v-else />
			</div>
		</a-spin>
		<!-- <div style="height: 100vh; width: 100%; background: red;">
			
		</div> -->
		<AddProjectModal :visible.sync="addProjectModalVisible" @onAddProject="onAddProject">
		</AddProjectModal>
	</div>
</template>

<script>
import { computed, onMounted, ref } from 'vue-demi';
import { getMyProjectList } from '../../api/projectService';
import router from '../../router';
import AddProjectModal from '../../views/itemCenter/components/addProjectModal.vue';
import ProjectListItem from './projectListItem.vue';
const colors = [
	`#FFBB00`,
	`#FF7300`,
	`#00C247`,
	`#1890FF`
]
export default {
	setup(props, context) {
		const itemKey = ref(context.root.$route.query.projectId ? context.root.$route.query.projectId : null);
		// 所有的项目列表
		const projectList = ref([]);
		// 我负责的项目
		const headProjectList = ref([]);
		// 我参与的项目
		const joinProjectList = ref([]);
		// 加载
		const loading = ref(false);
		// 搜索字段
		const searchValue = ref(``);
		// 初始化页面
		const initProject = async (isRefresh = false) => {
			loading.value = true;
			const res = await getMyProjectList();
			if (res.code === 200) {
				headProjectList.value = res.data.headinfo;
				joinProjectList.value = res.data.joininfo;
				if (createdProjectList.value.length > 0 && itemKey.value == null) {
					handleProjectClick(createdProjectList.value[0]);
				}
				else if (attendProjectList.value.length > 0 && itemKey.value == null) {
					handleProjectClick(attendProjectList.value[0]);
				}
				else if (itemKey.value) {
					const res = [...createdProjectList.value, ...attendProjectList.value].filter(element => {
						return itemKey.value == element.projectId
					});
					//console.log(res)
					res.length && handleProjectClick(res[0]);
					!res.length && router.replace({
						path: '/itemCenter'
					})
					context.emit("onProjectChange", { id: itemKey.value, title: selectedTitle.value, isRefresh: isRefresh })
				}
				// 数据都为空
				else if(createdProjectList.value.length === 0 && attendProjectList.value.length === 0) {
					context.emit("onNoProject")
				}
				//context.emit("onProjectChange", { id: itemKey.value, title: selectedTitle.value, isRefresh: isRefresh });
			}
			loading.value = false;
		}
		onMounted(async () => {
			initProject();
		});
		// 获取我创建的项目
		const createdProjectList = computed(() => {
			return headProjectList.value.filter((element) => {
				return element.name.indexOf(searchValue.value) !== -1;
			});
		});
		// 获取我参与的项目
		const attendProjectList = computed(() => {
			return joinProjectList.value.filter((element) => {
				return element.name.indexOf(searchValue.value) !== -1;
			});
		});
		// 点击项目
		const handleProjectClick = (item) => {
			selectedTitle.value = item.name;
			if (item.projectId == itemKey.value) {
				return;
			}
			else {
				itemKey.value = item.projectId;
				context.root.$router.replace({
					name: "itemCenter",
					query: {
						projectId: itemKey.value
					}
				});
				context.emit("onProjectChange", { id: itemKey.value, title: selectedTitle.value });
			}
		};
		// 选中项目的头部
		const selectedTitle = ref("");
		/***************             新建项目 start             ******************/
		// 新建项目弹窗 可见变量
		const addProjectModalVisible = ref(false);
		// 新建项目按钮点击
		const addProjectButtonClick = () => {
			addProjectModalVisible.value = true;
		}
		// 新建项目回调
		const onAddProject = async () => {
			loading.value = true;
			const res = await getMyProjectList();
			if (res.code === 200) {
				loading.value = false;
				headProjectList.value = res.data.headinfo;
				joinProjectList.value = res.data.joininfo;
				if (!itemKey.value) {
					if (createdProjectList.value.length > 0 && itemKey.value == null) {
						handleProjectClick(createdProjectList.value[0]);
					}
					else if (attendProjectList.value.length > 0 && itemKey.value == null) {
						handleProjectClick(attendProjectList.value[0]);
					}
					context.emit("onProjectChange", { id: itemKey.value, title: selectedTitle.value });
				}
			}
		}
		/***************             新建项目 end               ******************/
		/***************             删除项目后的回调            *****************/
		const handleProjectDelete = async (item) => {
			const { $route, $router } = context.root;
			if($route.query.projectId == item.projectId) {
				itemKey.value= null;
				/* itemKey.value = '';
				await $router.replace({
					path: $route.path,
					query: {}
				}); */
			}
			initProject(true);
		}
		/************************************************************************/
		/***************             编辑项目后的回调            *****************/
		const handleProjectEditted = async (newItem, oldItem) => {
			if(newItem.projectName !== oldItem.projectName) {
				initProject(true);
			}
		}
		return {
			itemKey,
			createdProjectList,
			attendProjectList,
			colors,
			handleProjectClick,
			loading,
			searchValue,
			addProjectModalVisible,
			addProjectButtonClick,
			onAddProject,
			initProject,
			handleProjectDelete,
			handleProjectEditted
		};
	},
	components: { AddProjectModal, ProjectListItem }
}
</script>

<style lang="less" scoped>
.contents {
	width: 272px;
	height: calc(100vh - 77px);
	background-color: white;
	margin-bottom: 32px;
	margin-top: 6px;
	color: rgba(0, 0, 0, 0.85);
	overflow-y: auto;
	box-shadow: @srims-primary-box-shadow;
	border-radius: 4px;
}

.btn {
	width: 32px;
	border: 1px solid #EAEEF3;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
	cursor: pointer;
}

.btn:hover {
	background-color: rgba(238, 238, 238);
}

.items {
	margin-top: 32px;
}

.items_msg {
	margin-top: 18px;

	.project-icon-ctn {
		padding: 4px 4px 0px 4px;
		border-radius: 2px;
		margin-right: 16px;
		margin-left: 16px;

		.project-icon {
			font-size: 20px;
			color: white;
			fill: white;
		}
	}
}

/* 
.msg {
	width: 240px;
	padding: 8px;
	border-radius: 4px;
	margin: 0 auto;
	margin-bottom: 10px;
	display: flex;
	align-items:flex-start;
	cursor: pointer;

	&:hover {
		background: #EEEEEE;
	}

	transition: .3s background ease-in-out;
}

.selected {
	background: #EEEEEE;
} */</style>
