<template>
    <a-modal width="680px" :visible="visible" title="邀请成员" @cancel="handleCancel">
        <div style="display: flex; justify-content: center; align-items: center;">
            <div style="font-size: 15px;">
                搜索成员 : 
            </div>
            <div style="width: 480px; margin-left: 16px;">
                <a-select style="width: 100%;" show-search :value="id"  @search="handleUserSearch"
                    @change="handleUserSelectChange" option-label-prop="label" placeholder="输入用户名称或手机号、邮箱" :filter-option="false"
                    :showArrow="false" >
                    
                    <a-select-option v-for="(user, userIndex) in searchList" :key="user.userId" :disabled="selectedIdList.indexOf(user.userId) !== -1 || memberIdList.indexOf(user.userId) !== -1 ">
                        <!-- {{  user.name  }} -->
                        <div style="display: flex; ">
                            <div>
                                <DefaultAvatar :url="user.photoMedia" :size="28" :name="user.name" ></DefaultAvatar>
                            </div>
                            <div style="margin-left: 10px;width: 0; flex: 1;">
                                <div style="word-wrap: break-word;">
                                    {{  user.name }} 
                                </div>
                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :title="user.phone">
                                    手机号码：{{ user.phone }}
                                </div>
                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :title="user.email">
                                    邮箱： {{ user.email  }}
                                </div>
                            </div>
                            <div v-if="selectedIdList.indexOf(user.userId) !== -1 " style="color: #1890FF;margin-left: 10px;background: #fff;">
                                已添加
                            </div>
                            <div v-if="memberIdList.indexOf(user.userId) !== -1" style="color: #1890FF;margin-left: 10px;background: #fff;">
                                已在项目组中
                            </div>
                           <!--  <div v-if="!user.email" style="color: #1890FF;margin-left: 10px;background: #fff;">
                                用户未绑定邮箱
                            </div> -->
                        </div>
                    </a-select-option>
                    <template slot="notFoundContent">
                        <a-spin v-if="fetching"  size="small" />
                        <a-empty v-else-if="!searchVal" description="输入名称或手机号、邮箱进行搜索"></a-empty>
                        <a-empty v-else-if="!fetching && searchVal && getFilterOption.length == 0" ></a-empty>
                    </template>
                    <!-- <a-empty v-if="!fetching && getFilterOption.length === 0 && searchVal"/> -->
                </a-select>
            </div>
        </div>
        <div style="margin-top: 32px;" class="table-ctn">
            <a-table rowKey="userId" :columns="tableColumns" :data-source="list" size="small" :pagination="false" :scroll="{ y: 500 }">
                <template slot="avatar" slot-scope="text, record, index">
                    <div>
                        <DefaultAvatar :url="record.photoMedia" :name="record.name"></DefaultAvatar>
                    </div>
                </template>
                <template slot="action" slot-scope="text, record, index">
                    <a-button type="link" @click="list.splice(index,1)"> 删除 </a-button>
                </template>
            </a-table>
        </div>
        <template slot="footer">
            <div style="display: flex; justify-content: center; align-items: center;">
                <a-button type="primary" :disabled="list.length === 0 || isSubmitting" @click="handleConfirm" :loading="isSubmitting" > 邀请 </a-button>
                <a-button :disabled="isSubmitting"  :loading="isSubmitting"  @click="handleCancel"> 取消 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { researchGroupFindUser } from '@/api/researchGroup.js'
import debounce from 'lodash/debounce';
import DefaultAvatar from '@/components/defaultAvatar.vue';
import {  inviteUser } from '@/api/projectService';
const tableColumns = [
    {
        title: '头像',
        dataIndex: 'avatar',
        scopedSlots: { customRender: "avatar" }
    },
    {
        title: '成员名称',
        dataIndex: 'name',
        scopedSlots: { customRender: "name" }
    },
    {
        title: '手机',
        dataIndex: 'phone',
        scopedSlots: { customRender: "name" }
    },
    {
        title: '邮箱',
        dataIndex: 'email',
        scopedSlots: { customRender: "email" }
    },
    {
        title: '操作',
        key:'action',
        scopedSlots: { customRender: "action" }
    }
]

export default {
    props: {
        visible: {
            default: false
        },
        memberList: {
            default: () => []
        },
        projectId: {
            default: ''
        }
    },
    setup(props, context) {
        const list = ref([]); // 已选择的列表
        const searchList = ref([]); // 搜索的列表
        const id = ref(undefined); // 选择用户的id
        const fetching = ref(false); // 是否正在加载
        const searchVal = ref(""); // 搜索的关键词
        // 搜索用户请求
        const searchUser = debounce(async (val) => {
            searchVal.value = val;
            if (!val.toString()) {
                searchList.value = [];
                return;
            }
            fetching.value = true;
            const res = await researchGroupFindUser({
                userKey: val
            });
            if (res.success) {
                searchList.value = res.data;
            }
            fetching.value = false;
        }, 800);
        // 用户搜索
        const handleUserSearch = (val) => {
            searchVal.value = val.toString().trim();
            if(val.toString().trim()) {
                fetching.value = true;
            }
            else {
                fetching.value = false;
            }
            searchList.value = [];
            //console.log(val)
            searchUser(val.toString().trim());
        };
        // 选择改变
        const handleUserSelectChange = (val, option) => {
            //console.log(searchList.value.filter(user => user.id === val)[0])
            //list.value.push(JSON.parse(JSON.stringify(option)));
            list.value.push(JSON.parse(JSON.stringify(searchList.value.filter(user => user.userId === val)[0])))
            id.value = undefined;
            searchVal.value = undefined;

        };

        // 已选择用户的id列表
        const selectedIdList = computed(() => {
            return list.value.map(user => user.userId);
        })
        // 已在项目组内的用户Id
        const memberIdList = computed(() => {
            return props.memberList;
        })
        // 过滤已选择的用户后的列表
        const getFilterOption = computed(() => {
            const ids = list.value.map(user => user.userId);
            return searchList.value.filter(user => {
                return ids.indexOf(user.userId) === -1;
            });
        });
        // 取消
        const handleCancel = () => {
            context.emit("update:visible", false);
        };

        const isSubmitting = ref(false); // 是否正在提交

        // 确认导入
        const handleConfirm = async () => {
           /*  context.emit('onAddMember', list.value);
            context.emit('update:visible', false) */
            isSubmitting.value = true;
            const res = await inviteUser({
                ProjectId: props.projectId,
                //MemberUserEmails: list.value.map(user => user.email)
                userIds: list.value.map(user => user.userId)
            });
            if(res.success) {
                context.emit('onAddMember', list.value);
                context.emit('update:visible', false)
            }
            else {

            }
            isSubmitting.value = false;

        }

        watch(() => props.visible, (val) => {
            if(val) {
                searchList.value = [];
                searchVal.value = '';
                list.value = [];
            }
        })
        return {
            list,
            searchList,
            id,
            handleUserSearch,
            handleUserSelectChange,
            tableColumns,
            getFilterOption,
            handleCancel,
            fetching,
            searchVal,
            selectedIdList,
            memberIdList,
            handleConfirm,
            isSubmitting
        };
    },
    components: { DefaultAvatar }
}
</script>

<style lang="less" scoped>
.table-ctn {
    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>