<template>
    <div>
        <a-modal :width="600" :visible.sync="visible" @cancel="handleCancel" title="邀请成员">
            <a-form-model ref="formRef" :model="formData" style="width: 95%;margin: 0 auto;" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 6 },
            }" :wrapper-col="{ span: 18 }" hideRequiredMark :rules="rules">
                <!--  <a-form-model-item ref="emaliFormItemRef" hasFeedback label="邀请成员" prop="MemberUserEmails">
                <a-input v-model="formData.MemberUserEmails" placeholder="请输入邮箱，以逗号分隔开" autocomplete="off"></a-input>
            </a-form-model-item> -->
            <a-form-model-item ref="emaliFormItemRef" hasFeedback  :labelCol="{
               span: 0
            }" prop="MemberUserEmails">
                <div style="display: flex; height: 60px;">
                    <a-button type="primary"
                        @click="handleAddMemberFromResearchTeamButtonClick">
                        + 添加课题组成员
                    </a-button>
                    <a-button type="primary"
                        style="margin-left: 24px;"
                        @click="inviteUserOusideButtonClick"
                       >
                        <span style="">+</span>
                        邀请课题组外成员
                    </a-button>
                </div>
            </a-form-model-item>
            </a-form-model>
            <div class="modal-content">
                <div class="member-count">
                    {{ userList.length }} 名成员
                </div>
                <div class="member-list">
                    <div v-for="(user, index) in userList" :key="index" class="member-item">
                        <div class="member-avatar member-item-clumn">
                            <img v-if="user.userPhoto" :src="user.userPhoto ? `${user.userPhoto}` : NotImage"
                                style="width: 32px; height: 32px;border-radius: 100%;margin-right: 8px;" />
                            <DefaultAvatar v-else style="margin-right: 8px;" :name="user.name">
                            </DefaultAvatar>
                            <span> {{ user.name }} </span>
                        </div>
                        <div class="user-email member-item-clumn">
                            {{ user.email }}
                        </div>
                        <div class="member-item-clumn">
                            {{ getMemberType(user.memberType) }}
                        </div>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <div class="btns-ctn">
                  <!--   <a-button class="concel-btn" @click="handleCancel">
                        取消
                    </a-button>
                    <a-button type="primary" class="confirm-btn" @click="handleConfirm">
                        确定
                    </a-button> -->
                </div>
            </template>
        </a-modal>
        <a-modal :visible="selectMemberFromResearchGroupModalVisible" title="选择课题组成员"
            @cancel="selectMemberFromResearchGroupModalVisible = false" :width="680">
            <a-spin :spinning="isResearchTeamMemeberLoading" tip="正在加载">
                <div class="modal-table-ctn">
                    <a-table  rowKey="userId" :columns="selectUserTableColumn" size="small"
                        :data-source="researchTeamUserList"
                        :row-selection="{ selectedRowKeys: selectedUserRowKeys, onChange: onUserSelectChange, }"
                        :pagination="false" bordered :scroll="{ y: 500 }">
                    </a-table>
                </div>
            </a-spin>
            <template slot="footer">
                <div style="display: flex; justify-content: center;">
                    <a-button type="primary" :loading="isAddingUserMember"
                        :disabled="isAddingUserMember || selectedUserRowKeys.length == 0"
                        @click="handleSubmitResearchTeamMember"> 确定 </a-button>
                    <a-button :loading="isAddingUserMember" :disabled="isAddingUserMember"
                        @click="selectMemberFromResearchGroupModalVisible = false;"> 取消 </a-button>
                </div>
            </template>
        </a-modal>
        <InvteUserOutsideResearchGroupModal :visible.sync="invteUserOutsideResearchGroupModalVisible" :memberList="existUserIdList" @onAddMember="onAddMember" :projectId="projectId"></InvteUserOutsideResearchGroupModal>
    </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { computed, h, ref, watch } from 'vue-demi';
import NotImage from '../../../../public/not.jpg';
//import { getProjectUserManager } from '../../../api/projectUserManager';
import { getProjectUserManager, inviteUser, addResearchGroupMemberToProject, getProjectUserByResearchGroupUser } from '../../../api/projectService';
import DefaultAvatar from '../../../components/defaultAvatar.vue';
import InvteUserOutsideResearchGroupModal from './invteUserOutsideResearchGroupModal.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        projectId: {
            type: String | null,
            required: true
        }
    },
    setup(props, context) {
        // 表单实例
        const formRef = ref(null);
        // 表单数据
        const formData = ref({
            MemberUserEmails: ""
        });
        // 邮箱校验
        const emailsValidator = (_rule, value, callback) => {
            if (!value.toString().trim()) {
                callback(new Error("请输入"));
            }
            else if (value.toString().indexOf("，") !== -1) {
                callback(new Error(`请使用英文逗号 “,”`));
            }
            else {
                const re = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                const emails = value.toString().split(",");
                emails.forEach(element => {
                    console.log(re.test(element.toString().trim()));
                    if (!re.test(element.toString().trim())) {
                        callback(new Error(`“${element.toString().trim()}” 不为邮箱`));
                    }
                    else {
                        const temp = userList.value.filter((user) => {
                            return user.email === element.toString().trim();
                        });
                        if (temp.length > 0) {
                            callback(new Error(`${element.toString().trim()}已在成员列表中`));
                        }
                    }
                });
                callback();
            }
        };
        // 校验规则
        const rules = ref({
            MemberUserEmails: {
                required: true,
                validator: emailsValidator
            }
        });
        const userTypes = ["", "普通成员", "项目助理", "审核人", "负责人"];
        // 取消操作
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const userList = ref([]);
        // 项目成员列表
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                formData.value = {
                    MemberUserEmails: ""
                };
                formRef.value && formRef.value.resetFields();
                const res = await getProjectUserManager({
                    projectid: props.projectId
                });
                if (res.code === 200) {
                    userList.value = res.data.data;
                }
                /* const res = await getProjectUserManager({
                    projectid: props.projectId
                 });
                 if(res.code === 200) {
                    projectUserList.value = res.data;
                 } */
            }
        });
        // 确认操作
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async (valid) => {
                if (valid) {
                    const res = await inviteUser({
                        ProjectId: props.projectId,
                        MemberUserEmails: formData.value.MemberUserEmails
                    });
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit("onAddUsers");
                        context.emit("update:visible", false);
                    }
                    else {
                        emaliFormItemRef.value.validateState = "error";
                        emaliFormItemRef.value.validateMessage = res.message;
                    }
                }
            });
            return;
        };
        // 项目成员
        const projectUserList = ref([]);
        // 邮箱表单项实例
        const emaliFormItemRef = ref(null);
        // 获取成员类型
        const getMemberType = (str) => {
            let res = [];
            const types = str.split(",");
            types.forEach((element) => {
                res.push(userTypes[parseInt(element)]);
            });
            return res.join("、");
        };

        /********************          选择课题组内的成员                    **********/
        const selectMemberFromResearchGroupModalVisible = ref(false);	// 从课题组内选择成员对话框可见变量

        const researchTeamUserList = ref([]);

        const isResearchTeamMemeberLoading = ref(false);	// 是否正在加载

        const isAddingUserMember = ref(false);	// 是否正在提交成员

        watch(() => selectMemberFromResearchGroupModalVisible.value, async (newVal) => {
            if (newVal) {
                isAddingUserMember.value = false;
                isResearchTeamMemeberLoading.value = true;
                selectedUserRowKeys.value = [];
                const res = await getProjectUserByResearchGroupUser({
                    projectId: props.projectId
                })
                if (res.success) {
                    researchTeamUserList.value = res.data;
                }
                isResearchTeamMemeberLoading.value = false;
            }

        })

        // 用户columns
        const selectUserTableColumn = [
            {
                title: '用户',
                dataIndex: 'name',
                slotScope: {
                    scopedSlots: { customRender: "name" }
                }
            },
            {
                title: '手机',
                dataIndex: 'phone'
            },
            {
                title: '邮箱',
                dataIndex: 'email'
            }
        ]

        const selectedUserRowKeys = ref([]);

        // 用户选择改变
        const onUserSelectChange = (val) => {
            selectedUserRowKeys.value = val;
        }
        // 添加课题组成员按钮点击事件
        const handleAddMemberFromResearchTeamButtonClick = () => {
            selectMemberFromResearchGroupModalVisible.value = true;
        }


        const handleSubmitResearchTeamMember = async () => {
            isAddingUserMember.value = true;
            const res = await addResearchGroupMemberToProject({
                userIds: selectedUserRowKeys.value,
                projectId: props.projectId
            });
            if (res.success) {
                message.success('成功添加');

                selectMemberFromResearchGroupModalVisible.value = false;
                context.emit('onAddMemberFromResearchGroup')
              /*   getPorjectSetData();
                msgBox.value.init() */
                const dres = await getProjectUserManager({
                    projectid: props.projectId
                });
                if (dres.code === 200) {
                    userList.value = dres.data[0].data;
                }
            }
            isAddingUserMember.value = false;

        }


        /****************************************************************************/
        /*********************              邀请课题组外的用户对话框           ****************/
        const invteUserOutsideResearchGroupModalVisible = ref(false);   // 对话框可见变量

        // 邀请课题组外成员按钮点击事件
        const inviteUserOusideButtonClick = () => {
            invteUserOutsideResearchGroupModalVisible.value = true;
        }

        // 已经在课题组内的用户ID
        const existUserIdList = computed(() => {
            return userList.value.map(user => user.userId);
        })

        // 邀请成功后的回调
        const onAddMember = (list) => {
            
            Modal.success({
                content: h('span', { } ,[
                    h('span', {  }, '已向'),
                    h('span', { style: { 'color': '#1890ff' },  }, `${list[0].name}`),
                    h('span', { }, list.length > 1 ? `等${list.length}用户发送邀请。`: '发送邀请,'),
                    h('span', { }, '等待用户接受。')
                ])
            })
        }
         /****************************************************************************/
        return {
            formRef,
            formData,
            handleCancel,
            handleConfirm,
            rules,
            NotImage,
            projectUserList,
            emaliFormItemRef,
            userList,
            getMemberType,
            selectMemberFromResearchGroupModalVisible,
            selectUserTableColumn,
            selectedUserRowKeys,
            onUserSelectChange,
            researchTeamUserList,
            handleAddMemberFromResearchTeamButtonClick,
            isResearchTeamMemeberLoading,
            isAddingUserMember,
            handleSubmitResearchTeamMember,
            invteUserOutsideResearchGroupModalVisible,
            inviteUserOusideButtonClick,
            existUserIdList,
            onAddMember
        };
    },
    components: { DefaultAvatar, InvteUserOutsideResearchGroupModal }
}
</script>

<style lang="less" scoped>
.member-count {
    margin-left: 8px;
    font-size: 14px;
    color: #333333;
}

.member-list {
    max-height: 300px;
    overflow-y: auto;
    margin-top: 16px;

    .member-item {
        padding: 8px 0px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .member-avatar {
            display: flex;
            align-items: center;
            word-break: break-all;
        }

        .member-item-clumn {
            width: 30%;
        }
    }
}

.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

.invite-button {
    padding: 6px 16px;
    color: white;
    //margin-left: 24px;
    margin-right: 30px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}
.invite-button + .invite-button {
    margin-left: 24px;
}
</style>

<style lang="less" scoped>
.modal-table-ctn {
	/deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}
</style>