<template>
    <div :class="itemKey == item.projectId ? 'msg selected' : 'msg'" @click="handleProjectClick(item)"
          @mouseover="mouseIn"
            @mouseleave="mouseOut">
        <div class="project-icon-ctn" :style="{ background: colors[index % 4] }">
            <svg-icon name="group" class="project-icon"></svg-icon>
        </div>
        <div style="font-size: 14px;color: rgba(0, 0, 0, 0.85);  word-break: break-all;text-align: left; display: flex; align-items: center; margin-right: 26px;"> <span>{{ item.name }}
            ({{ item.code }})</span></div>
       <!--  <transition enter-active-class=" animate__fadeIn" leave-active-class=" animate__fadeOut"> -->
           <!--  <div :class="[`operation-ctn`]" v-show="isOperationShow" @click.stop="() => { }"> -->
            <div :class="[`operation-ctn`]"  @click.stop="() => { }">
                <a-popover trigger="click" :visible="isActive" @visibleChange="handleClickChange" placement="right">
                    <a-icon type="appstore" style="margin: auto;" @click.stop="() => { }" />
                    <template slot="content">
                        <div class="operation-item" v-if="item.operationAuthority" @click="handleEditClick">
                            <a-icon type="edit" /> 编辑 
                        </div>
<!-- 
                        <div @click="handleDeleteClick" class="operation-item" v-if="item.operationAuthority">
                            <a-icon type="delete" /> 删除
                        </div> -->
                        <div class="operation-item" @click="handleProjectInfoClick">
                            <a-icon type="info-circle" /> 详情
                        </div>
                    </template>
                </a-popover>
            </div>
       <!--  </transition> -->
       <ProjectDetailModal :visible.sync="projectDetailModalVisible" :id="item.projectId" :onlyPreview="isProjectInfoOnlyPreview " :showJump="false"  @onUpdateProjectInfo="onUpdateProjectInfo">

        </ProjectDetailModal>

    </div>
</template>

<script>
import { message, Modal } from 'ant-design-vue';
import { computed, h, ref } from 'vue-demi';
import { getProjectDetail,cancelProject } from '../../api/projectService';
import ProjectDetailModal from '../../views/itemCenter/components/projectDetailModal.vue';
const colors = [
	`#FFBB00`,
	`#FF7300`,
	`#00C247`,
	`#1890FF`
]

export default {
    props: {
        item: {
            default: {}
        },
        itemKey: {
            default: ""
        },
        index: {
            default: 0
        }
    },
    setup(props, context) {
        const isHover = ref(false);
        const mouseIn = () => {
            isHover.value = true;
        };
        //const mouseOut = ref(false);
        const mouseOut = () => {
            isHover.value = false;
        };
        const isActive = ref(false);
        const isOperationShow = computed(() => {
            return isActive.value || isHover.value;
        });
        const handleClickChange = (visible) => {
            isActive.value = visible;
        };
        const handleDeleteClick = () => {
            isActive.value = false;
            Modal.confirm({
                content: h('div', { }, [
                    h('span', { }, '是否确认删除'),
                    h('span', { style: { color: '#1890ff' }}, `${ props.item.name }(${ props.item.code })`),
                    '？' 
                ]),
                onOk: async () => {
                    const res = await cancelProject({
                        projectId: props.item.projectId
                    });
                    if(res.success) {
                        Modal.success({
                            content: h('div', { }, [
                                    h('span', { }, '成功删除'),
                                    h('span', { style: { color: '#1890ff' }}, `${ props.item.name }(${ props.item.code })`)
                                ])
                        })
                        context.emit('onDelete', props.item);
                    }
                    else {
                        Modal.error({
                            content: res.message
                        })
                    }
                }
            })
        };
        const handleDetailClick = () => {
        };
        const handleEditClick = async () => {
            isActive.value = false;
            isProjectInfoOnlyPreview.value = false;
            projectDetailModalVisible.value = true;
            /* const res = await getProjectDetail({
                id: props.item.projectId
            });
            if(res.data[0].hasRole === 1) {
                isProjectInfoOnlyPreview.value = false;
                projectDetailModalVisible.value = true;
            }
            else {
                message.error(res.message)
            } */

        };
        // 项目列点击事件 
        const handleProjectClick = () => {
            context.emit("onClick");
        };

        // 所属项目详情对话框可见变量
        const projectDetailModalVisible = ref(false);
        // 所属项目点击事件
        const handleProjectInfoClick = () => {
            isProjectInfoOnlyPreview.value = true;
            projectDetailModalVisible.value = true;
            isActive.value = false;
        }
        // 项目信息是否是仅查看状态
        const isProjectInfoOnlyPreview = ref(true);

        // 修改项目信息后的回调
        const onUpdateProjectInfo = (newVal) => {
            context.emit('onEdit', newVal)
        }


        return {
            isHover,
            mouseIn,
            mouseOut,
            isActive,
            isOperationShow,
            handleClickChange,
            colors,
            handleDeleteClick,
            handleDetailClick,
            handleEditClick,
            handleProjectClick,
            handleProjectInfoClick,
            isProjectInfoOnlyPreview,
            projectDetailModalVisible,
            onUpdateProjectInfo
        };
    },
    components: { ProjectDetailModal }
}
</script>

<style lang="less" scoped>
.operation-ctn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translate(0%, -50%);
    //transform: translateY(-50%);
    cursor: pointer;
    //height: 100%;
    font-size: 20px;
    //background: #EEEEEE;
    padding-left: 10px;
    transition: .3s background ease-in-out;
    /deep/ .ant-popover-inner-content {
        padding: 0px;
    }
}

.active-op {
    background: #EEEEEE;
}

.operation-item {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background: #f7f7f7;
    }
}

.msg {
    width: 240px;
    padding: 8px;
    border-radius: 4px;
    /* background: #EEEEEE; */
    margin: 0 auto;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    position: relative;

    &:hover {
        background: #EEEEEE;
    }

    transition: .3s background ease-in-out;
}

.selected {
    background: #EEEEEE;
}

.project-icon-ctn {
    padding: 4px 4px 0px 4px;
    border-radius: 2px;
    margin-right: 16px;
    //margin-left: 16px;

    .project-icon {
        font-size: 20px;
        color: white;
        fill: white;
    }
}
</style>