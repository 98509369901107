<!-- 生成实验报告弹窗 -->
<template>
    <a-modal :width="800" :visible="visible" @cancel="handleCancel" title="批量导出实验报告" :maskClosable="false">
        <a-spin :spinning="spinning" :tip="tip">
            <a-form-model style="width: 90%;margin: 0 auto;" ref="formRef" :model="formData" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 5 },
            }" :wrapper-col="{ span: 19 }">
                <a-form-model-item v-show="loadingStatu === 'ready'" label="报告内容" prop="content"
                    :rules="[{ required: true, validator: contentValidator }]">
                    <a-checkbox v-if="visible" :defaultChecked="true" @change="(e) => onCheckedChange(e, 'report')">
                        实验报告
                    </a-checkbox>
                </a-form-model-item>
                <a-form-model-item v-show="loadingStatu === 'ready'" label="水印">
                    <a-tooltip>
                        <a-checkbox :checked="true" :disabled="true" >
                        </a-checkbox>
                    </a-tooltip>
                </a-form-model-item>
               <!--  <a-form-model-item v-show="loadingStatu === 'ready'" label="水印">
                    <a-checkbox :checked="hasWaterMark" @change="handleWaterMarkCheck">
                    </a-checkbox>
                </a-form-model-item> -->
               <!--  <a-form-model-item v-if="hasWaterMark && loadingStatu === 'ready'" label="水印内容" prop="waterMarkContent"
                    :rules="[{ required: true, whitespace: true, message: '请输入' }, { max: 10, message: '最多不超过十个字符' }]">
                    <a-input v-model="formData.waterMarkContent" placeholder="请输入"></a-input>
                </a-form-model-item> -->
                <a-form-model-item v-show="loadingStatu !== 'ready'" :wrapper-col="{ span: 24 }">
                    <div v-show="loadingStatu === 'loading'">
                        <a-icon type="sync" spin style="margin-right: 4px;word-break: break-all;"/>  正在批量导出中，请耐心等候...
                    </div>
                    <div v-show="loadingStatu === 'done'" >
                        <a-alert :message="`批量导出执行完毕`" type="success" show-icon />
                    </div>
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 24 }">
                    <div class="table-ctn">
                        <a-table rowKey="experimentId" :columns="allColumns" size="small" :data-source="exportRows"
                            class="exper-table" :scroll="{ y: 350, x: 600 }" :pagination="false" bordered>
                            <template slot="outputStatu" slot-scope="text, record, index">
                                <div v-if="record.outputStatu === 'ready'">      
                                    <a-alert message="准备导出" type="info" show-icon />
                                </div>
                                <div v-else-if="record.outputStatu === 'loading'">
                                    <a-icon type="sync" spin style="margin-right: 4px;word-break: break-all;"/> 导出中
                                </div>
                                <div v-else-if="record.outputStatu === 'error'">
                                    <a-alert :message="`导出失败，错误信息： ${  record.errorMsg  }`" type="error" show-icon />
                                </div>
                                <div v-else-if="record.outputStatu === 'success' && record.saveFileWarning">
                                    <a-alert :message="`成功导出：${  record.successMsg  }`" type="success" show-icon />
                                    <a-alert :message="`警告信息：${  record.saveFileWarning  }`" style="margin-top: 5px;" type="warning" show-icon />
                                </div>
                                <div v-else-if="record.outputStatu === 'success'">
                                    <a-alert :message="`成功导出：${  record.successMsg  }`" type="success" show-icon />
                                </div>
                               
                            </template>
                        </a-table>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button v-if="loadingStatu == 'ready'" class="concel-btn" :loading="spinning" @click="handleCancel">
                    取消
                </a-button>
                <a-button v-if="loadingStatu !== 'done'" :disabled="loadingStatu == 'loading'" :loading="loadingStatu=='loading'" type="primary" class="confirm-btn"
                    @click="hanleConfirm">
                    开始导出
                </a-button>
                <a-button v-if="loadingStatu === 'done'"  @click="handleCancel">
                    关闭
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

.table-ctn {

    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }
}
</style>

<script>
import { computed, nextTick, ref, watch } from '@vue/composition-api';
import { getExperimentReport, getReportInitData, makeExperimentReport } from '../../api/experiment';
import { message, Modal } from 'ant-design-vue';
import JSZip from 'jszip';
import dayjs from 'dayjs';
import { isExcelIOLoad, loadExcelIO } from '../../plugins/KFormDesign/spreadjs';
//import '../../../public/scripts/'

const allColumns = [
    {
        title: '实验编号',
        dataIndex: "experimentNo",
        scopedSlots: { customRender: "experimentNo" }
    },
    {
        title: '实验名称',
        dataIndex: 'title'
    },
    {
        title: '创建人',
        dataIndex: 'createName'
    },
    {
        title: '导出状态',
      /*   width: '120px', */
        scopedSlots: { customRender: "outputStatu" }
    }
]


export default {
    props: ["visible", "Ids", "columns"],
    setup(props, context) {
        // 对话框取消
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        // 表格ref
        const formRef = ref(null);
        // 表格数据
        const formData = ref({
            fileName: "实验报告",
            fileType: ".pdf",
            content: ["report"],
            waterMarkContent: '',
        });

       
        const blobToObj = (data) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsText(data, 'utf-8');
                reader.onload = function () {
                    try {
                        resolve(JSON.parse(reader.result))
                    } catch (error) {
                        resolve({
                            code: 200,
                            message: '获取文件信息成功'
                        })
                    }
                }
            })
        }


        // 确定按钮点击
        const hanleConfirm = () => {
            formRef.value.validate(async (valid) => {
                if (valid) {
                    loadingStatu.value = 'loading';
                    const res = await startBulkOutput().catch(error => {
                        message.error(`批量导出错误`)
                    });
                    if(res) {
                        message.success('批量生成成功')
                    }
                    loadingStatu.value = 'done';
                }
            })
            //const re = /[ \/ | \\ | \: | \| | \* | \" | \< | \> | \?]*/g
        };

        const startBulkOutput = async () => {
            const outputList = [];
            for(let i = 0; i < exportRows.value.length; ++i) {
                outputList.push(new Promise(async (resolve, reject) => {
                    exportRows.value[i].outputStatu = 'loading';
                    const initRes = await getReportInitData({
                        Id: exportRows.value[i].experimentId
                    });
                    if(initRes.code !== 200) {
                        exportRows.value[i].errorMsg = typeof initRes.message == 'string' ? initRes.message : JSON.stringify(initRes.message);
                        exportRows.value[i].outputStatu = 'error';
                        resolve(false);
                        return ;
                    }
                    const content = JSON.parse(initRes.data.content)
                    const fileName = `实验报告-${initRes.data.title}`;
                    const excelList = content.list.filter(item => item.type === 'excel-pro');
                    const fileList = excelList.map((item, index) => {
                        return {
                            key: item.key,
                            fileName: `附件${index + 1}-${item.label}.xlsx`
                        }
                    })
                    const res = await getExperimentReport({
                        experimentId: exportRows.value[i].experimentId,
                        hasWaterMark: hasWaterMark.value,
                        waterMarkContent: hasWaterMark.value ? formData.value.waterMarkContent : ''
                    })
                    if (res.type === 'application/json') {
                        const error = await blobToObj(res);
                        message.error(error.message)
                        //loading.value = false;
                        exportRows.value[i].outputStatu = 'error';
                        exportRows.value[i].errorMsg = error.message;
                        resolve(false);
                        return;
                    }
                    if (res.status === 200) {
                        const pdfBlob = new Blob([res.data], {
                            type: 'application/octet-stream'
                        });
                        if (excelList.length > 0) {
                            tip.value = '正在生成附件...';
                            const zip = new JSZip();
                            const folder = zip.folder(`实验报告`);
                            folder.file(`实验报告-${initRes.data.title}-${initRes.data.experimentNo}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.pdf`, pdfBlob);
                            try {
                                for (let i = 0; i < excelList.length; ++i) {
                                    const excelRes = await getSingleExcelFile(excelList[i].options.customData, fileList[i].fileName);
                                    if (excelRes.succcess) {
                                        folder.file(fileList[i].fileName, excelRes.blob, {

                                        });
                                    }
                                }
                            } catch (error) {
                                message.error(`${fileName}:附件生成失败`);
                                exportRows.value[i].saveFileWarning = '附件生成失败';
                                exportRows.value[i].outputStatu = 'warning';
                                resolve(false);
                            }

                            zip.generateAsync({ type: "blob" }).then(function (content) {
                                const saveFileName =  `${fileName}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.zip`;
                                // see FileSaver.js
                                saveAs(content, saveFileName);
                                message.success(`导出 '${saveFileName}' 成功`);
                                exportRows.value[i].outputStatu = 'success';
                                exportRows.value[i].successMsg = ` 保存为 《 ${saveFileName} 》`
                                resolve(true);
                            });
                        }
                        else {
                            const saveFileName = `${fileName}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.pdf`;
                            saveAs(pdfBlob, saveFileName );
                            message.success(`导出 '${saveFileName}' 成功`);
                            exportRows.value[i].outputStatu= 'success';
                            exportRows.value[i].successMsg = ` 保存为 《 ${saveFileName} 》`
                            resolve(true);
                        }
                    }
                    else {
                        exportRows.value[i].outputStatu = 'error';
                        exportRows.value[i].errorMsg = res.message;
                        resolve(false);
                    }
                    
                }))
            }
            try {
                const res = await Promise.all(outputList);
                if(res.filter(item => !item)) {

                }
            } catch (error) {
                return Promise.reject(error)
            }
        }

        // 获取单个excel文件的文件流
        const getSingleExcelFile = async (json, fileName) => {
            //const GCExcel = await import('../../../public/scripts/gc.spread.excelio.15.1.1.min.js');
            return new Promise((resolve, reject) => {
                const excelio = new GC.Spread.Excel.IO();
                //const excelio = new GCExcel.value.IO();
                excelio.save(json, function (blob) {
                    resolve({
                        succcess: true,
                        blob: blob
                    })
                }, function (error) {
                    resolve({
                        succcess: false
                    })
                })
            })

        }
        // 报告内容勾选
        const onCheckedChange = (e, type) => {
            if (e.target.checked) {
                formData.value.content.push(type);
            }
            else {
                const index = formData.value.content.indexOf(type);
                index !== -1 && formData.value.content.splice(index, 1);
            }
        };
        // 内容校验器
        const contentValidator = async (rule, value, callback) => {
            if (value.length < 1) {
                return callback(new Error("请选择报告内容"));
            }
            else {
                callback();
                return callback();
            }
        };
        // 是否正在加载
        const loading = ref(false);
        //
        const spinning = computed(() => {
            return loading.value;
        })
        // 实验详情数据
        const experimentDeatail = ref({
            title: "实验报告",
            content: ""
        });
        // 加载提示
        const tip = ref("正在加载中");

        // 批量导出的列数据
        const exportRows = ref([]);

        // 整体导出状态
        const loadingStatu = ref('ready');

        // 监听可见
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                if (!isExcelIOLoad.value) {
                    await loadExcelIO();
                }
                loadingStatu.value = 'ready';
                nextTick(() => {
                    formRef.value && formRef.value.resetFields();
                })
                
                exportRows.value = props.columns.map(item => {
                    const newItem = JSON.parse(JSON.stringify(item));
                    newItem.outputStatu = 'ready';
                    return newItem;
                })
                
            }
        }, { immediate: true, deep: true });

        const PDFRef = ref(null);

        const GCExcel = ref(null);

        const loadingMessage = ref('正在加载中');
        // 是否存在水印
        const hasWaterMark = ref(false);
        // 水印更改
        const handleWaterMarkCheck = (e) => {
            hasWaterMark.value = e.target.checked;
        }
        // 文件名合法检测
        const fileNameValidator = async (_rule, value, callback) => {
            const re = /[ \/ | \\ | \: | \| | * | \" | \< | \> | \?]+/g;
            //console.log(re.test(value.toString()))
            if (re.test(value.toString())) {
                callback(new Error('文件名不能包含以下字符： / \\ : * " < > | ?'))
            }
            else {
                callback();
            }
        }
        return {
            handleCancel,
            formRef,
            formData,
            onCheckedChange,
            hanleConfirm,
            contentValidator,
            experimentDeatail,
            loading,
            tip,
            spinning,
            loadingMessage,
            hasWaterMark,
            handleWaterMarkCheck,
            fileNameValidator,
            allColumns,
            exportRows,
            loadingStatu
        };
    }
}
</script>