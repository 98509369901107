<template>
    <a-modal :width="680" :visible.sync="visible" @cancel="handleCancel" title="设置项目模板" :bodyStyle="{ padding: `10px ` }">
        <a-table rowKey="id" :loading="loading" size="small" :columns="columns" :data-source="tableData" :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" >
            <template slot="operation" slot-scope="text, record, index">
                <a-button type="link" @click="handleCheckTemplate(record)"> 查看 </a-button>
            </template>
        </a-table>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button :disabled="loading || isSame" type="primary" :loading="loading" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, watch, ref } from 'vue-demi';
import { getSetProjectExperimentTemplateIntitData, setProjectExperimentTemplate } from '../../../api/experimentTemplate';

const columns = [
    {
        title: '实验模板名称',
        dataIndex: 'name',
        width: '35%'
    },
    {
        title: '实验类型',
        dataIndex: 'experimentTypeName',
        width: '20%'
    },
    {
        title: '模板描述',
        dataIndex: 'description',
        width: '35%'
    },
    {
        title: '',
        width: '10%',
        scopedSlots: { customRender: "operation" }
    }
]

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        projectId: {
            type: String | null,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const loading = ref(false);
        const handleConfirm = async () => {
            loading.value = true;
            const res = await setProjectExperimentTemplate({
                projectId: props.projectId,
                experimentTemplateList: selectedRowKeys.value
            });
            if(res.code === 200 || res.code === 204) {
                message.success(res.message);
                context.emit('update:visible', false);
            }
            return ;
        }
        const selectedRowKeys = ref([]);
        // 已选择的行数据
        const selectedRows = ref([]);
        // 当前已选择行的
        const onSelectChange = (rowKeys, rows) => {
            // 取出所有已选择过行的key值
            const allKeys = selectedRows.value.map((element) => element.fileId);
            // 遍历，取出未存在于已选择行数组中的值并push
            rows.forEach((element) => {
                if (allKeys.indexOf(element.fileId) === -1) {
                    selectedRows.value.push(element)
                }
            })
            // 等同于所有已选行
            selectedRowKeys.value = rowKeys;
        }
        // 列表数据
        const tableData = ref([]);
        // 初始选择的模板id
        const initSelectedKeys = ref([]);
        // 是否一样
        const isSame = computed(() => {
            return JSON.stringify(initSelectedKeys.value) === JSON.stringify(selectedRowKeys.value);
        })
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                selectedRowKeys.value = [];
                selectedRows.value = [];
                loading.value = true;
                const res = await getSetProjectExperimentTemplateIntitData({
                    projectId: props.projectId
                });
                if(res.code === 200) {
                    tableData.value = res.data;
                    const selectedList = tableData.value.filter(element => {
                        return element.selected;
                    })
                    selectedRowKeys.value = selectedList.map(element => element.id);
                    initSelectedKeys.value = [...selectedRowKeys.value];
                    loading.value = false;
                }
            }
        })

        const handleCheckTemplate = (record) => {
            const { $router } = context.root;
            const href = $router.resolve({
                name: 'experimentTemplate',
                query: {
                    id: record.id,
                    mode: 'preview'
                }
            })
            window.open(href.href, '_blank')
        }
        return {
            handleCancel,
            handleConfirm,
            loading,
            columns,
            selectedRowKeys,
            selectedRows,
            onSelectChange,
            tableData,
            isSame,
            handleCheckTemplate
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    .concel-btn {
        background: #eeeeee;
    }
}
/deep/ .ant-table-thead {
    background: #F0F5FF;
}

/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
    margin: 0px;
}

.default-pagination {
    text-align: center;
    margin-top: 24px;
}
td {
    word-break: break-all;
}
</style>