<template>
	<a-spin :spinning="loading" tip="正在加载中...">
		<div class="content" ref="contentRef">
			<div style="display: flex;align-items: flex-start;margin: 24px 0pt;position: relative; width: 100%; "
				v-for="(item, index) in msgList">
				<img v-if="item.userPhoto" :src="item.userPhoto ? `${item.userPhoto}` : NotImage"
					style="width: 32px; height: 32px; border-radius: 100%;margin-top: 4px;margin-left: 8px;z-index: 11;">
				<DefaultAvatar v-else :name="item.userName"
					style="width: 32px;border-radius: 100%;margin-top: 4px;margin-left: 8px;z-index: 11;">

				</DefaultAvatar>
				<div style="width: 0.5px;height: 100%;background-color: rgba(0, 0, 0, 0.15);position: absolute;left: 23.5px;top: 32px;z-index: 10;"
					v-if="index !== projectNotice.length - 1"></div>
				<div style="flex: 1; width: 0px;margin: 0 8px;">
					<div style="display: flex;justify-content: space-between;align-items: center;">
						<div
							style="font-size: 14px;color: rgba(0, 0, 0, 0.85);word-break: break-all; max-width: 50%; text-align: left;">
							{{ item.userName }}</div>
						<div style="font-size: 12px;color: rgba(0, 0, 0, 0.45); margin-left: 10px;max-width: 50%;">{{
								item.createTime
						}}</div>
					</div>
					<div style="font-size: 12px;color: rgba(0, 0, 0, 0.65);text-align: left;margin-top: 2px; width: 100%; word-break: break-all;">
						<div v-html="`<div>${item.notices}</div>`" @click.prevent="handleNavigateTo($event)"></div>
					</div>
				</div>
			</div>
			<a-empty style="margin: auto;" v-if="!loading && msgList.length == 0 " description="加载消息出错"></a-empty>
			<div v-show="isOverPage && !moreLoading && !loading" style="color: rgba(0,0,0,0.45);padding-bottom:16px; margin-top: 30px;">
				已加载全部消息~
			</div>
			<a-spin v-show="!loading && moreLoading" size="small" :spinning="moreLoading" tip="正在加载中..." :delay="300">
				<div v-show="!loading && moreLoading" style="height: 70px; background: #ffffff;"></div>
			</a-spin>
		</div>
	</a-spin>
</template>

<script>
import { useScroll } from '@vueuse/core';
import { computed, onMounted, ref, watch } from 'vue-demi';
import NotImage from '../../public/static/not.jpg';
import DefaultAvatar from './defaultAvatar.vue';
import { getProjectNotice } from '../api/projectService';
import { message } from 'ant-design-vue';
import { getExperimentPermission } from '../api/experiment';
import router from '../router';
export default {
	props: {
		projectNotice: {
			type: Array,
			default: () => []
		},
		projectId: {
			type: null | String,
			default: () => null
		}
	},
	setup(props, context) {
		const contentRef = ref(null);
		const PageSize = ref(15);
		const PageNo = ref(1);
		const count = ref(1);
		const { x, y, isScrolling, arrivedState, directions } = useScroll(contentRef, {
			offset: { top: 50, bottom: 100 }
		});
		const msgList = ref([]);
		const loading = ref(false);
		const moreLoading = ref(false);
		// 是否越界
		const isOverPage = computed(() => {
			const maxPage = Math.ceil(count.value / PageSize.value);
			return PageNo.value + 1 > maxPage;
		});
		// 关联项目放生更改
		watch(() => props.projectId, async (newVal) => {
			if (newVal) {
				init();
			}
		})
		// 触底触发
		watch(() => arrivedState, (newVal) => {
			if (arrivedState.bottom) {
				load();
			}
		}, { deep: true })

		// 加载下一页
		const load = async () => {
			if (isOverPage.value) {
				return;
			}
			PageNo.value = PageNo.value + 1;
			await getProjectMessage();
		}

		// 初始化/ 重新初始化
		const init = async () => {
			PageNo.value = 1;
			count.value = 1;
			msgList.value = [];
			await getProjectMessage();
			contentRef.value && (contentRef.value.scrollTop = 0);
			moreInit();
		}
		// 更多初始化
		const moreInit = async () => {
			if(isOverPage.value) {
				return ;
			}
			if(contentRef.value.scrollHeight <= contentRef.value.clientHeight) {
				//PageNo.value = PageNo.value + 1;
				await load();
				moreInit();
			}
		}
		const getProjectMessage = async () => {
			if (PageNo.value === 1) {
				loading.value = true;
			}
			else {
				moreLoading.value = true;
			}
			const res = await getProjectNotice({
				ProjectId: props.projectId,
				PageNo: PageNo.value,
				PageSize: PageSize.value
			});
			if (res.code === 200) {
				count.value = res.data.count;
				msgList.value.splice(msgList.value.length, 0, ...res.data.data);
			}
			if (PageNo.value === 1) {
				loading.value = false;
			}
			else {
				moreLoading.value = false;
			}
		}
		onMounted(() => {
			if (props.projectId) {
				init();
			}
		})

		const handleNavigateTo = async (e) => {
			if(e.target.tagName === 'A') {
				const experimentRes =  e.target.href.match(new RegExp(`id=(.*?)&projectId`));
				//const projectRes = e.target.href.match(new RegExp(`&projectId=(\S*)`));
				const index = e.target.href.indexOf('projectId=');
				if(experimentRes) {
					// 鉴权
					const res = await getExperimentPermission({
						experimentId: experimentRes[1]
					});
					if(res.code === 200 ) {
						if(res.data.openPermission) {
							const href =  router.resolve({
								name: 'experiment',
								query: {
									mode: res.data.readWritePermission ? 'edit' : 'preview',
									id: experimentRes[1],
									projectId: index !== -1 ? e.target.href.substr(index + 'projectId='.length, e.target.href.length) : undefined
								}
							});
							window.open(href.href, '_blank');
						}
						else {
							message.error('无访问实验的权限');
						}
					}
				}
				else {
					message.error('连接错误')
				}
			}
			else {

			}
		}
		return {
			NotImage,
			contentRef,
			isOverPage,
			msgList,
			loading,
			getProjectMessage,
			moreLoading,
			handleNavigateTo,
			init
		};
	},
	components: { DefaultAvatar }
}
</script>

<style scoped>
.content {
	width: 100%;
	height: calc(100vh - 190px);
	background: #FFFFFF;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.85);
	overflow-x: hidden;
}
</style>