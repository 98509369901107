import { render, staticRenderFns } from "./projectListItem.vue?vue&type=template&id=30151cdc&scoped=true&"
import script from "./projectListItem.vue?vue&type=script&lang=js&"
export * from "./projectListItem.vue?vue&type=script&lang=js&"
import style0 from "./projectListItem.vue?vue&type=style&index=0&id=30151cdc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30151cdc",
  null
  
)

export default component.exports