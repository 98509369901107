<template>
	<div style="display: flex;">
		<itemMenu style="z-index: 600;" @onProjectChange="onProjectChange" ref="itemMenu" @onNoProject="onNoProject">
		</itemMenu>
		<div class="exper-contents">
			<a-spin :spinning="loading" tip="正在加载..." :delay="300">
				<div v-if="projectId" class="head">
					<div class="project-icon-ctn" :style="{ background: `#FF7300` }">
						<svg-icon name="group" class="project-icon"></svg-icon>
					</div>
					<div v-show="!projectSet.hasrole || projectSet.hasrole !== 1"
						style="font-size: 18px;color: rgba(0, 0, 0, 0.85);font-weight: 550; padding: 5px;">{{
							projectTitle
						}}</div>
					<a-tooltip v-show="!isTitleEditting && projectSet.hasrole && projectSet.hasrole === 1" title="点击编辑"
						@click="handleEditTitle">
						<div style="font-size: 18px;color: rgba(0, 0, 0, 0.85);font-weight: 550; padding: 5px; text-align: left;"
							class="project-title">{{ projectTitle }}</div>
					</a-tooltip>
					<div v-show="isTitleEditting && projectSet.hasrole && projectSet.hasrole === 1"
						class="project-title-input-ctn">
						<a-input style="width: 100%;" ref="titleInput" placeholder="请输入" v-model="edittingTitle"
							size="large" @blur="handleTitleInputBlur" :maxLength="50" show-limit
							@pressEnter="handleTitleInputBlur"></a-input>
					</div>
					<div style="flex:1"></div>
					<div class="users">
						<a-tooltip v-for="(item, index) in lstUser" :title="item.userName" :key="index">
							<!-- <img src="../../../public/not.jpg" style="width: 32px;border-radius: 100%;margin-right: 8px;"> -->
							<img v-if="item.userPhoto" :src="`${item.userPhoto}`"
								style="width: 32px;height: 32px;border-radius: 100%;margin-right: 8px;" />
							<DefaultAvatar v-else :name="item.userName">
							</DefaultAvatar>
						</a-tooltip>
						<a-tooltip v-show="moreUser.length > 0">
							<template slot="title">
								<div v-for="(moreItem, mIndex) in moreUser" :key="mIndex">
									<p> {{ moreItem.userName }} </p>
								</div>
							</template>
							<div v-if="moreUser.length > 0" style="position: relative;">
								<div
									style="position: absolute;width: 32px;height: 32px;background: rgba(8, 16, 27, 0.65);border-radius: 100%;font-size: 16px;display: flex;justify-content: center;align-items: center;">
									+{{ moreUser.length }}
								</div>

								<img v-if="moreUser.length && moreUser[0].userPhoto" :src="`${moreUser[0].userPhoto}`"
									style="width: 32px;height: 32px;border-radius: 100%;margin-right: 8px;">
								<DefaultAvatar v-else-if="moreUser.length && !moreUser[0].userPhoto"
									:name="moreUser[0].userName">
								</DefaultAvatar>
							</div>
						</a-tooltip>
					</div>
					<!-- <div v-if="projectSet.lstMenu.hasJoinProduct === 1" class="invite-button" style="margin-right: 0px;"
						@click="handleAddMemberFromResearchTeamButtonClick">
						+ 添加课题组成员
					</div> -->
					<div v-if="projectSet.lstMenu.hasJoinProduct === 1" class="invite-button"
						@click="handleInviteButtonClick">
						<span style="">+</span>
						邀请 / 添加成员
					</div>
					<a-popover placement="bottomRight" v-model="settingPopoverVisible" trigger="click">
						<a-tooltip placement="topLeft" style="" title="项目设置/操作">
							<a-badge size="small" :offset="[2, -8]" :overflowCount="99" :count="getBadgeCount">
								<div>
									<a-icon class="option-icon" style="color: #4E5969;font-size: 20px;cursor: pointer;"
										type="menu" />
								</div>
							</a-badge>
						</a-tooltip>
						<template slot="content">
							<div class="option" v-if="projectSet.lstMenu.hasRemoveProject === 1"
								@click="handleHandOverOptionClick">
								移交项目
							</div>
							<div class="option" v-if="projectSet.lstMenu.hasEditAdui === 1"
								@click="setAssistantButtonClick">
								编辑审核权限
							</div>
							<div class="option" v-if="projectSet.lstMenu.hasProjectUserInfo === 1"
								@click="handleGoToMember">
								项目组成员
							</div>
							<div class="option" v-if="projectSet.lstMenu.hasSetExperimentMoudle === 1"
								@click="handSetProjectExperimentClick">
								设置实验模板
							</div>
							<div class="option" v-if="projectSet.lstMenu.hasProjectInfo === 1"
								@click="handleProjectDetailClick">
								项目详情
							</div>
							<a-badge :overflowCount="99" :count="projectSet.lstMenu.goodFaithBehaviorReplyCount">
								<div class="option" v-if="projectSet.lstMenu.hasGoodFaithBehaviorReply === 1"
									@click="handleAuditGoodFaith">
									诚信审核
								</div>
							</a-badge>
						</template>
					</a-popover>
				</div>
				<div v-if="projectId" class="i-content">
					<div class="table-content">
						<div class="search-panal">
							<div class="search-options">
								<div class="search-option">
									<a-input v-model="experimentNo" placeholder="实验编号" allowClear
										@pressEnter="handleSearch"></a-input>
								</div>
								<div class="search-option">
									<a-input v-model="createName" placeholder="创建人" allowClear
										@pressEnter="handleSearch"></a-input>
								</div>
								<div class="search-option">
									<a-range-picker valueFormat="YYYY-MM-DD" v-model="createTimeRage"
										style="width: 100%;" />
								</div>
								<div class="search-option">
									<a-select v-model="experimentStatus" style="width: 100%;" placeholder="实验状态">
										<a-select-option
											v-for="(item, index) in [{ label: '全部', value: 4 }, { label: '编辑', value: 1 }, { label: '申请归档', value: 2 }, { label: '归档成功', value: 3 }]"
											:value="item.value" :key="index">
											{{ item.label }}
										</a-select-option>
									</a-select>
								</div>
							</div>
							<div class="search-operation">
								<a-button type="primary" class="search-btn" @click="handleSearch">查询</a-button>
								<a-button class="reset-btn" @click="resetSearch"> 重置 </a-button>
							</div>
							<!--  bug，必须再table 渲染外再引用一次  -->
							<svg-icon name="report" style="display: none;"></svg-icon>
							<svg-icon name="history" style="display: none;"></svg-icon>
							<svg-icon name="star" style="display: none;"></svg-icon>
							<svg-icon name="author" style="display: none;"></svg-icon>
						</div>
						<div class="multi-operation-panal">
							<a-badge :count="selectedRowKeys.length">
								<a-button type="primary" :disabled="selectedRowKeys.length === 0"
									@click="handleMakeReportBulkClick"> <a-icon type="download" /> 批量导出报告 </a-button>
							</a-badge>
						</div>
						<div class="table-ctn">
							<a-table rowKey="experimentId"
								:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectionChange, getCheckboxProps: getCheckboxProps, fixed: true }"
								:custom-row="customRow" :columns="allColumns" :data-source="list" :pagination="false"
								size="small" class="exper-table" :scroll="{ y: tableHeight, x: 1100 }"
								:loading="tableLoading">
								<template slot="experimentNo" slot-scope="text, record, index">
									<a :class="[openExperimentList.indexOf(record.experimentId) !== -1 ? 'open-link' : '']"
										v-if="record.hasEditView !== 3" @click="handleGoToExperiment(record)">
										{{ record.experimentNo }}
									</a>
									<a-tooltip title="无该实验的查看权限" v-else>
										<div>
											<a disabled>
												{{ record.experimentNo }}
											</a>
										</div>
									</a-tooltip>
								</template>
								<template slot="conclusion" slot-scope="text, record, index">
									<div :style="{ color: getConclusionColors(record.conclusion) }">
										{{ getConclusionText(record.conclusion) }}
									</div>
								</template>
								<template slot="experimentStatus" slot-scope="text, record, index">
									<a-tooltip v-if="getExperimentStatuText(record.experimentStatus)" title="查看流程状态"
										@click="handleStatusFlowClick(record.experimentId)">
										<!-- <div class="statu-btn"
											:style="{ background: getExperimentFlowStatuColor(record.experimentStatus), padding: `5px 8px`, 'text-align': 'center', width: '72px', 'border-radius': '4px', color: '#ffffff', cursor: 'pointer' }">
											{{ getExperimentStatuText(record.experimentStatus) }}
										</div> -->
										<a-tag :color="getExperimentFlowStatuColor(record.experimentStatus)">
									{{ getExperimentStatuText(record.experimentStatus) }}
								</a-tag>
									</a-tooltip>
									<div v-else>
										————
									</div>
								</template>
								<template slot="operation" slot-scope="text, record, index">
									<div class="operations">
										<!-- <a-tooltip title="克隆" @click="handleExperimentCloneButtonClick(record)">
									<svg-icon name="copy" class="icon1"></svg-icon>
								</a-tooltip> -->
										<a-tooltip v-if="record.button.hasDwonLoad === 1" title="生成实验报告"
											@click="handleMakeReportButtonClick(record)">
											<svg-icon name="report" class="icon1"></svg-icon>
										</a-tooltip>
										<a-tooltip v-if="record.button.hasHistoryView === 1" title="操作记录或版本记录"
											@click="goToExperimentRecord(record)">
											<svg-icon name="history" class="icon1"></svg-icon>
										</a-tooltip>
										<a-tooltip v-if="record.button.hasAuthorized === 1" title="授权">
											<svg-icon name="author" class="icon1"
												@click="handleAuthorizeButtonClick(record)">
											</svg-icon>
										</a-tooltip>
										<a-tooltip v-if="record.button.hasCollect === 2" title="点击收藏">
											<svg-icon name="star" class="icon1" @click="handleCollectionClick(record)">
											</svg-icon>
										</a-tooltip>
										<a-tooltip v-else title="取消收藏">
											<svg-icon name="star" style="fill:#FAAD14; " class="icon1"
												@click="handleCollectionCancelClick(record)"></svg-icon>
										</a-tooltip>
										<!-- <a-tooltip
											v-if="record.experimentStatus === 1" 
											title="删除" @click="handleExperimentDeleteClick(record)">
											<a-icon type="delete" theme="filled"  class="icon1"/>
										</a-tooltip> -->
									</div>
								</template>
							</a-table>
						</div>
						<div class="default-pagination">
							<a-pagination :show-total="total => `共 ${total} 条`"  @change="handlePageChange" v-model="current" :pageSize="10"
								:total="totalRows" show-less-items />
						</div>
					</div>
					<div class="mesaage-content">
						<AddMsgBox ref="msgBox" :projectNotice="projectNotice" :projectId="projectId" />
					</div>
				</div>
				<a-result v-if="!projectId" status="404" title="项目数据为空" sub-title="请先选择一个项目，或点击左侧新建项目"
					style="margin-top: 80px;">
				</a-result>
			</a-spin>
		</div>
		<InviteUserModal :visible.sync="inviteUserModalVisible" :userList="projectSet.lstUser" :projectId="projectId"
			@onAddUsers="onAddUsers" @onAddMemberFromResearchGroup="onAddMemberFromResearchGroup"></InviteUserModal>
		<HandOverHeadModal :visible.sync="handOverHeadModalVisible" :projectId="projectId" @onHandOver="onHandOver">
		</HandOverHeadModal>
		<MakeReprotModal v-if="makeReportModalVisible" :visible.sync="makeReportModalVisible" :Id="makeReportId"
			@onMakeReportSuccess="onMakeReportSuccess"></MakeReprotModal>
		<SetAssistantModal :visible.sync="setAssistantModalVisible" :projectId="projectId"></SetAssistantModal>
		<!-- <AuthorizeModal  -->
		<StatusFlowDrawer :visible.sync="statusFlowDrawerVisible" :experimentId="statusFlowExperimentId">
		</StatusFlowDrawer>
		<AuthorizationUserModal :visible.sync="authorizationUserModalVisible" :ExperimentId="authorizationExperimentId">
		</AuthorizationUserModal>
		<ProjectDetailModal :visible.sync="projectDetailModalVisible" :id="projectId"
			@onUpdateProjectInfo="onUpdateProjectInfo">
		</ProjectDetailModal>
		<SetProjectTemplateModal :visible.sync="setProjectTemplateModalVisible" :projectId="projectId">
		</SetProjectTemplateModal>
		<makeReportBulk :visible.sync="makeReportBulkVisible" :columns="selectedRows">

		</makeReportBulk>
		
	</div>
</template>

<script>
import itemMenu from '../../components/common/itemMenu.vue';
import { ref, computed, nextTick, watch, h } from 'vue-demi';
import { editProjectName, getProjectExperimentList, getProjectNotice, getProjectSet, getProjectUserByResearchGroupUser,addResearchGroupMemberToProject } from '../../api/projectService';
import InviteUserModal from './components/inviteUserModal.vue';
import NotImage from '../../../public/not.jpg';
import HandOverHeadModal from './components/handOverHeadModal.vue';
import AddMsgBox from '../../components/addMsgBox.vue';
import MakeReprotModal from '../../components/experimentManage/makeReportFromServer.vue';
import { addMyCollection, cancleMyCollection, getAntEditExperiment, cancelExperiment } from '../../api/experiment';
import SetAssistantModal from './components/setAssistantModal.vue';
import AuthorizeModal from '../../components/authorizeModal.vue';
import { message, Modal } from 'ant-design-vue';
import StatusFlowDrawer from './components/statusFlowDrawer.vue';
import AuthorizationUserModal from '../../components/authorizationUserModal.vue';
import ProjectDetailModal from './components/projectDetailModal.vue';
import SetProjectTemplateModal from './components/setProjectTemplateModal.vue';
import DefaultAvatar from '../../components/defaultAvatar.vue';
import router from '../../router';
import { getExperimentFlowStatuColor } from '../../utils/enums';
import { useDocumentVisibility, useTextSelection } from '@vueuse/core';
import { getExperimentContextMenu } from './utils';
import makeReportBulk from '../../components/experimentManage/makeReportBulk.vue';

const tableHeight = document.documentElement.clientHeight - 380;
// 实验所有列
const allColumns = [
	{
		title: '实验编号',
		dataIndex: "experimentNo",
		scopedSlots: { customRender: "experimentNo" }
	},
	{
		title: '实验名称',
		dataIndex: 'title'
	},
	{
		title: '关键词',
		dataIndex: 'keywords'
	},
	{
		title: '创建人',
		dataIndex: 'createName'
	},
	{
		title: '创建时间',
		dataIndex: 'createTime'
	},
	{
		title: '最后修改人',
		dataIndex: 'lastUpdataName'
	},
	{
		title: '结论',
		dataIndex: "conclusion",
		scopedSlots: { customRender: "conclusion" }
	},
	{
		title: '流程状态',
		dataIndex: "experimentStatus",
		scopedSlots: { customRender: "experimentStatus" }
	},
	{
		title: '操作',
		dataIndex: "operation",
		width: 180,
		fixed: "right",
		scopedSlots: { customRender: "operation" }
	}
]

export default {
	components: {
		itemMenu,
		InviteUserModal,
		HandOverHeadModal,
		AddMsgBox,
		MakeReprotModal,
		SetAssistantModal,
		AuthorizeModal,
		StatusFlowDrawer,
		AuthorizationUserModal,
		ProjectDetailModal,
		SetProjectTemplateModal,
		DefaultAvatar,
		makeReportBulk
	},
	setup(props, context) {
		// 项目id
		const projectId = ref(null);
		// 项目头部数据
		const projectSet = ref({
			// 是否有修改项目头的权限
			hasrole: 2,
			// 项目操作列表
			lstMenu: {},
			// 项目用户
			lstUser: []
		})
		// 头部标题
		const projectTitle = ref('');
		// 列表数据
		const lstUser = computed(() => {
			return projectSet.value.lstUser.slice(0, projectSet.value.lstUser.length > 7 ? 7 : projectSet.value.lstUser.length);
		})
		// 获取更多的用户
		const moreUser = computed(() => {
			if (projectSet.value.lstUser.length < 7) {
				return []
			}
			return projectSet.value.lstUser.splice(7, projectSet.value.lstUser.length);
			//return projectSet.value.lstUser.slice(0, projectSet.value.lstUser.length > 7 ? 7 : projectSet.value.lstUser.length);
		})
		// 项目消息列表
		const projectNotice = ref([]);
		// 加载中
		const loading = ref(false);
		// 获取项目消息
		/* const getProjectMessage = async () => {
			const res = await getProjectNotice({
				ProjectId: projectId.value
			});
			if (res.code === 200) {
				projectNotice.value = res.data;
			}
		} */
		// 头部
		const getPorjectSetData = async () => {
			const res = await getProjectSet({ ProjectId: projectId.value });
			if (res.code === 200) {
				projectSet.value = res.data;
				console.log(res.data)
			}
		}
		// 项目消息列表
		const msgBox = ref(null);

		// 项目点击回调
		const onProjectChange = async ({ id, title, isRefresh = false }) => {
			projectTitle.value = title;
			if (!id) {
				return;
			}
			if (title) {
				document.title = `${title} | 项目管理`
			}
			loading.value = true;
			projectId.value = id
			projectTitle.value = title;
			projectSet.value = {
				// 项目操作列表
				lstMenu: {},
				// 项目用户
				lstUser: []
			}
			selectedRowKeys.value = [];
			/* const res = await getProjectSet({ ProjectId: projectId.value });
			if (res.code === 200) {
				projectSet.value = res.data[0];
			} */
			resetSearch();
			if (!isRefresh) {
				current.value = 1;
				resetSearch();
			}
			await Promise.all([getPorjectSetData(), getExperimentList()])
			/* await getPorjectSetData();
			await getProjectMessage();
			
			await getExperimentList(); */
			loading.value = false;
		}
		// 设置浮层可见变量
		const settingPopoverVisible = ref(false);
		// 邀请成员弹窗对话框
		const inviteUserModalVisible = ref(false);
		// 邀请新成员点击事件
		const handleInviteButtonClick = () => {
			inviteUserModalVisible.value = true;
		}
		// 成功邀请成员回调
		const onAddUsers = () => {
			return;
		}
		/********************                       移交项目 start                 ************************/
		// 移交项目对话框可见变量
		const handOverHeadModalVisible = ref(false);
		// 移交变量选项点击事件
		const handleHandOverOptionClick = () => {
			handOverHeadModalVisible.value = true;
			settingPopoverVisible.value = false;
		}
		// 移交后的回调
		const onHandOver = async () => {
			itemMenu.value && itemMenu.value.initProject(true);
			loading.value = true;
			await Promise.all([getPorjectSetData(), getExperimentList(), msgBox.value.init()]);
			loading.value = false;
			/* getPorjectSetData();
			getProjectMessage();
			getExperimentList(); */
		}
		/********************                       移交项目 end                   ************************/

		// 项目组成员点击事件
		const handleGoToMember = (record) => {
			const href = context.root.$router.resolve({
				name: 'projectMemberManage',
				query: {
					id: projectId.value,
					title: projectTitle.value
				}
			})
			window.open(href.href, '_blank')
		}

		/********************                      编辑审核全选 start                 ************************/
		// 编辑审核对话框可见变量
		const setAssistantModalVisible = ref(false);
		// 编辑审核点击事件
		const setAssistantButtonClick = () => {
			setAssistantModalVisible.value = true;
			settingPopoverVisible.value = false;
		}
		/********************                      编辑审核全选 end                   ************************/
		/********************                         项目详情 start                  ***********************/
		// 详情对话框可见变量
		const projectDetailModalVisible = ref(false);
		// 项目详情点击事件
		const handleProjectDetailClick = () => {
			projectDetailModalVisible.value = true;
			settingPopoverVisible.value = false;
		}
		// 项目详情更改回调事件
		const onUpdateProjectInfo = async () => {
			itemMenu.value && itemMenu.value.initProject(true);
			loading.value = true;
			/* getPorjectSetData();
			getProjectMessage();
			getExperimentList(); */
			await Promise.all([getPorjectSetData(), getExperimentList(), msgBox.value.init()]);
			loading.value = false;
		}
		// 左侧菜单栏
		const itemMenu = ref(null);
		/********************                         项目详情 end                    ***********************/
		/********************                        设置实验模板 start              ************************/
		// 设置模板点击事件
		const handSetProjectExperimentClick = () => {
			setProjectTemplateModalVisible.value = true;
			settingPopoverVisible.value = false;
		}
		// 设置实验模板对话框可见变量
		const setProjectTemplateModalVisible = ref(false);
		/********************                        设置实验模板 end                ************************/
		/********************                         诚信审核 start                 ***********************/
		const handleAuditGoodFaith = () => {
			const href = router.resolve({
				name: 'goodFaithAudit',
				query: {
					projectId: projectId.value
				}
			});
			window.open(href.href, "_blank");
		}
		/********************                      项目实验 start                  ************************/
		// 实验编号
		const experimentNo = ref(null);
		// 创建人
		const createName = ref(null);
		// 创建时间
		const createTimeRage = ref([]);
		// 实验流程状态
		const experimentStatus = ref(undefined);
		// 查询
		const handleSearch = async () => {
			current.value = 1;
			selectedRowKeys.value = [];
			getExperimentList();
			return
		}
		// 实验列表
		const list = ref([]);

		// 重置
		const resetSearch = () => {
			experimentNo.value = null;
			createTimeRage.value = [];
			createName.value = null;
			experimentStatus.value = undefined;
			selectedRowKeys.value = [];
			return
		}
		// 当前页数
		const current = ref(1);
		// 页面总数
		const totalRows = ref(0);
		// 页面改变
		const handlePageChange = async (page, pageSize) => {
			selectedRowKeys.value = [];
			getExperimentList();
		}
		// 列表加载变量
		const tableLoading = ref(false);

		// 获取列表
		const getExperimentList = async () => {
			tableLoading.value = true;
			const res = await getProjectExperimentList({
				projectId: projectId.value,
				experimentNo: experimentNo.value,
				createName: createName.value,
				createStartTime: createTimeRage.value.length === 2 ? createTimeRage.value[0] : null,
				createEndTime: createTimeRage.value.length === 2 ? createTimeRage.value[1] : null,
				experimentStatus: experimentStatus.value,
				pageSize: 10,
				pageNum: current.value
			});
			if (res.code === 200) {
				list.value = res.data.lstexperiment;
				totalRows.value = res.data.count;
			}
			tableLoading.value = false;
		}
		const getExperimentHref = (record) => {
			const mode = record.experimentStatus === 1 ? 'edit' : 'preview';
			const href = context.root.$router.resolve({ name: 'experiment', query: { mode: mode, id: record.experimentId } });
			return href.href;
		}
		// 已打开的实验列表
		const openExperimentList = ref([]);
		// 编辑模式下，检测是否有人在编辑
		const handleGoToExperiment = async (record) => {
			if (openExperimentList.value.indexOf(record.experimentId) === -1) {
				openExperimentList.value.push(record.experimentId);
			}
			if (record.hasEditView === 2 && record.experimentStatus === 1) {
				if (!navigator.sendBeacon) {
					message.error('该浏览器不支持编辑实验，请更换别的浏览器')
					return;
				}
				const res = await getAntEditExperiment({ experimentId: record.experimentId });
				if (res.code === 200 && res.data.onlineEdit) {
					Modal.info({
						title: '提示',
						content: res.data.message
					})
				}
				else if (res.code === 200 && !res.data.onlineEdit) {
					const href = context.root.$router.resolve({ name: 'experiment', query: { mode: 'edit', id: record.experimentId, projectId: projectId.value } });
					window.open(href.href, '_blank')
				}
			}
			else {
				const href = context.root.$router.resolve({ name: 'experiment', query: { mode: 'preview', id: record.experimentId, projectId: projectId.value } });
				window.open(href.href, '_blank')
			}

		}
		// 获取结论颜色
		const getConclusionColors = (conclusion) => {
			if (conclusion === 1) {
				return '#52C41A';
			}
			else if (conclusion === 2) {
				return '#FF4D4F'
			}
			else if (conclusion === 3) {
				return `rgba(0, 0, 0, 0.45)`
			}
			else {
				return `rgba(0, 0, 0, 0.85)`
			}
		}
		// 获取结论值
		const getConclusionText = (conclusion) => {
			if (conclusion === 1) {
				return '成功';
			}
			else if (conclusion === 2) {
				return '失败'
			}
			else if (conclusion === 3) {
				return `停止`
			}
			else {
				return `——`
			}
		}
		// 流程状态
		const getExperimentStatuText = (statu) => {
			if (statu === 1) {
				return '编辑'
			}
			else if (statu === 2) {
				return '申请归档'
			}
			else if (statu === 3) {
				return '归档成功'
			}
		}
		/******************          生成实验报告对话框 start           *******************/
		// 生成实验报告对话框
		const makeReportModalVisible = ref(false);
		// 生成实验报告的实验的id
		const makeReportId = ref(null);
		// 生成实验报告按钮点击事件
		const handleMakeReportButtonClick = (record) => {
			makeReportId.value = record.experimentId;
			makeReportModalVisible.value = true;
		}
		// 生成报告成功回调
		const onMakeReportSuccess = () => {
			msgBox.value && msgBox.value.init()
		}
		/******************          生成实验报告对话框  end            *******************/
		/******************              授权对话框 start              *******************/
		// 授权对话框
		const authorizationUserModalVisible = ref(false);
		// 授权实验Id
		const authorizationExperimentId = ref(null);
		// 授权点击事件
		const handleAuthorizeButtonClick = (record) => {
			authorizationExperimentId.value = record.experimentId;
			authorizationUserModalVisible.value = true;
		}
		/******************              授权对话框  end               *******************/
		// 操作记录点击
		const goToExperimentRecord = (record) => {
			const url = context.root.$router.resolve({
				name: 'experimentRecord',
				query: {
					tab: 1,
					id: record.experimentId
				}
			});
			window.open(url.href, '_blank');
		}
		// 点击收藏
		const handleCollectionClick = async (record) => {
			const res = await addMyCollection({
				ExperimentId: record.experimentId,
				Title: record.title
			})
			if (res.code === 204 || res.code === 200) {
				message.success('收藏成功');
				record.button.hasCollect = 1;
			}
			return
		}
		// 取消收藏
		const handleCollectionCancelClick = async (record) => {
			const res = await cancleMyCollection({
				ExperimentId: record.experimentId
			})
			if (res.code === 204 || res.code === 200) {
				message.success('成功取消');
				record.button.hasCollect = 2;
			}
		}
		/******************     流程状态查看 start        *****************/
		// 流程状态抽屉可见变量
		const statusFlowDrawerVisible = ref(false);
		// 流程选中id
		const statusFlowExperimentId = ref(null);
		// 查看流程状态点击事件
		const handleStatusFlowClick = (id) => {
			statusFlowExperimentId.value = id;
			statusFlowDrawerVisible.value = true;
		}
		/******************     流程状态查看 end          *****************/
		/********************                      项目实验 end                    ************************/

		/****************************              项目标题修改  start            *********************************/
		// 项目头部是否编辑中
		const isTitleEditting = ref(false);
		// 编辑中的头部
		const edittingTitle = ref('');
		// 点击编辑头部
		const handleEditTitle = () => {
			edittingTitle.value = projectTitle.value;
			isTitleEditting.value = true;
			nextTick(() => {
				titleInput.value && titleInput.value.focus();
			})
		}
		// 编辑头部失焦
		const handleTitleInputBlur = async () => {
			// 编辑为空或未编辑则不进行操作
			if (edittingTitle.value === projectTitle.value || edittingTitle.value === '') {
				isTitleEditting.value = false;
			}
			else {
				const res = await editProjectName({
					projectId: projectId.value,
					projectName: edittingTitle.value
				});
				if (res.code === 200 || res.code === 204) {
					projectTitle.value = edittingTitle.value;
					itemMenu.value && itemMenu.value.initProject(true);
				}
				isTitleEditting.value = false;
			}
		}
		// 标题输入框实例
		const titleInput = ref(null);
		/****************************              项目标题修改  end              *********************************/
		const handleTest = () => {
			settingPopoverVisible.value = false;
		}

		// 项目顶部badge数量
		const getBadgeCount = computed(() => {
			return projectSet.value?.lstMenu?.goodFaithBehaviorReplyCount;
		});
		/*****************************             页面可见刷新  start            ************************************/
		const visibility = useDocumentVisibility();
		watch(() => visibility.value, async (newVal) => {
			if (newVal === 'visible') {
				itemMenu.value && itemMenu.value.initProject(true);
			}
		})
		/*****************************             页面可见刷新  end              ************************************/
		/*****************************             添加实验后刷新 start           ************************************/
		watch(() => context.root.$store.state.trigger.addExperimentTrigger, async () => {
			itemMenu.value && itemMenu.value.initProject(true);
		})
		/******************************            添加实验后刷新 end             ************************************/
		const state = useTextSelection();
		const customRow = (record, index) => {
			return {
				on: {
					contextmenu: (e) => {
						if (state.text.value === '' && record.hasEditView !== 3) {
							e.preventDefault();
							getExperimentContextMenu(e, record, projectId.value, openExperimentList);
						}

					}
				}
			}
		}

		/******************************             多选处理 start                 ***********************************/
		// 选中的Key值
		const selectedRowKeys = ref([]);

		// 选中改变事件
		const onSelectionChange = (value) => {
			selectedRowKeys.value = value;
		}

		// 选中的行
		const selectedRows = computed(() => {
			return list.value.filter(item => {
				return selectedRowKeys.value.includes(item.experimentId);
			})
		})

		// 是否可选
		const getCheckboxProps = (record) => {
			return {
				props: {
					disabled: record.hasEditView === 3
				}
			}
		}

		// 批量生成报告可见变量
		const makeReportBulkVisible = ref(false);

		// 批量导出报告点击事件
		const handleMakeReportBulkClick = () => {
			makeReportBulkVisible.value = true;
		}

		/******************************             多选处理 end                   ***********************************/
		/********************        删除实验                     ********************/

		// 实验删除操作点击
		const handleExperimentDeleteClick = (record) => {
			Modal.confirm({
				//content: `是否确认删除记录本\r\n  ${props.item.noteBookName} ？`,
				/*  content: <div>
					 是否确认删除记录本 <span style="color: #1890ff;"></span>
				 </div>, */
				content: h('div', {}, [h('span', {}, '是否确认删除实验 '), h('span', { style: { color: '#1890ff' } }, `${record.title}(${record.experimentNo})`), ' ？']),
				onOk: async () => {
					/* await new Promise((resolve) => {
						setTimeout(() => {
							resolve()
						}, 3000)
					}) */
					const res = await cancelExperiment({
						experimentId: record.experimentId
					});
					if (res.success && (res.code === 200 || res.code === 204)) {
						/* Modal.success({
							content: h('div', {}, [h('span', {}, '成功删除 '), h('span', { style: { color: '#1890ff' } }, `${record.title}(${record.experimentNo})`), ' ？'])
						}) */
						Modal.success({
							content: h('div', {}, [h('span', {}, '成功删除 '), h('span', { style: { color: '#1890ff' } }, `${record.title}(${record.experimentNo})`)])
						})
						//searchExperiment();
						getExperimentList();
					}
					else {
						Modal.error({
							content: res.message
						})
					}
				}
			})
		}
		/****************************************************************************/
		/********************          当项目列表中没有数据时回调            **********/
		const onNoProject = () => {
			projectId.value = '';
			projectSet.value = {
				// 是否有修改项目头的权限
				hasrole: 2,
				// 项目操作列表
				lstMenu: {},
				// 项目用户
				lstUser: []
			}
			msgBox.value = null;
			list.value = [];
			projectTitle.value = ''
		}
		/****************************************************************************/

		/********************          选择课题组内的成员                    **********/
		/* const selectMemberFromResearchGroupModalVisible = ref(false);	// 从课题组内选择成员对话框可见变量

		const researchTeamUserList = ref([]);

		const isResearchTeamMemeberLoading = ref(false);	// 是否正在加载

		const isAddingUserMember = ref(false);	// 是否正在提交成员

		watch(() => selectMemberFromResearchGroupModalVisible.value , async (newVal) => {
			if(newVal) {
				isAddingUserMember.value = false;
				isResearchTeamMemeberLoading.value = true;
				selectedUserRowKeys.value = [];
				const res = await getProjectUserByResearchGroupUser({
					projectId: projectId.value
				})
				if(res.success) {
					researchTeamUserList.value = res.data;
				}
				isResearchTeamMemeberLoading.value = false;
			}
			
		})

		// 用户columns
		const selectUserTableColumn = [
			{
				title: '用户',
				dataIndex: 'name',
				slotScope: {
					scopedSlots: { customRender: "name" }
				}
			}
		]

		const selectedUserRowKeys = ref([]);
		
		// 用户选择改变
		const onUserSelectChange = (val) => {
			selectedUserRowKeys.value = val;
		}
		// 添加课题组成员按钮点击事件
		const handleAddMemberFromResearchTeamButtonClick = () => {
			selectMemberFromResearchGroupModalVisible.value = true;
		} 


		const handleSubmitResearchTeamMember = async () => {
			isAddingUserMember.value = true;
			const res = await addResearchGroupMemberToProject({
				userIds: selectedUserRowKeys.value,
				projectId: projectId.value
			});
			if(res.success) {
				message.success('成功添加');
				
				selectMemberFromResearchGroupModalVisible.value = false;
				getPorjectSetData();
				msgBox.value.init()
			}
			isAddingUserMember.value = false;
		} */

		// 从课题组添加成员成功回调
		const onAddMemberFromResearchGroup = () => {
			getPorjectSetData();
            msgBox.value.init()
		}


		/****************************************************************************/
		return {
			projectSet,
			lstUser,
			moreUser,
			onProjectChange,
			handleSearch,
			resetSearch,
			projectTitle,
			settingPopoverVisible,
			handleTest,
			handleInviteButtonClick,
			inviteUserModalVisible,
			NotImage,
			projectId,
			onAddUsers,
			handleHandOverOptionClick,
			handOverHeadModalVisible,
			projectNotice,
			experimentStatus,
			createTimeRage,
			createName,
			experimentNo,
			current,
			totalRows,
			handlePageChange,
			allColumns,
			tableHeight,
			getExperimentHref,
			list,
			getConclusionColors,
			getConclusionText,
			getExperimentStatuText,
			makeReportModalVisible,
			handleMakeReportButtonClick,
			makeReportId,
			goToExperimentRecord,
			handleCollectionClick,
			handleCollectionCancelClick,
			setAssistantModalVisible,
			setAssistantButtonClick,
			onHandOver,
			loading,
			statusFlowDrawerVisible,
			statusFlowExperimentId,
			handleStatusFlowClick,
			handleGoToMember,
			authorizationUserModalVisible,
			authorizationExperimentId,
			handleAuthorizeButtonClick,
			handleProjectDetailClick,
			projectDetailModalVisible,
			onUpdateProjectInfo,
			itemMenu,
			handleGoToExperiment,
			openExperimentList,
			handSetProjectExperimentClick,
			setProjectTemplateModalVisible,
			handleEditTitle,
			isTitleEditting,
			handleTitleInputBlur,
			titleInput,
			edittingTitle,
			getBadgeCount,
			handleAuditGoodFaith,
			getExperimentFlowStatuColor,
			msgBox,
			customRow,
			getExperimentContextMenu,
			onMakeReportSuccess,
			selectedRowKeys,
			onSelectionChange,
			getCheckboxProps,
			selectedRows,
			makeReportBulkVisible,
			handleMakeReportBulkClick,
			tableLoading,
			handleExperimentDeleteClick,
			onNoProject,
			onAddMemberFromResearchGroup
			/* selectMemberFromResearchGroupModalVisible,
			selectUserTableColumn,
			selectedUserRowKeys,
			onUserSelectChange,
			researchTeamUserList,
			handleAddMemberFromResearchTeamButtonClick,
			isResearchTeamMemeberLoading,
			isAddingUserMember,
			handleSubmitResearchTeamMember */
		}
	}
}
</script>

<style lang="less" scoped>
.project-title {
	border: 1px solid transparent;
	border-radius: 4px;
	margin-right: 50px;

	&:hover {
		border-color: #40a9ff;
	}
}

.project-title-input-ctn {
	width: 400px;

	.a-input {
		width: 100%;
	}
}

.project-icon-ctn {
	padding: 4px 4px 0px 4px;
	border-radius: 3px;
	margin-right: 16px;
	margin-left: 16px;

	.project-icon {
		font-size: 33px;
		color: white;
		fill: white;
	}
}

.users {
	width: 280px;
	height: 100%;
	align-items: center;
	display: flex;
	justify-content: flex-end;
}

.exper-contents {
	margin-top: 16px;
	width: 79%;
	flex-grow: 1;
	min-height: calc(100vh - 100px);
	background-color: white;
	margin-left: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	box-shadow: @srims-primary-box-shadow;
	border-radius: 4px;

	.head {
		width: 100%;
		height: 64px;
		border-bottom: 1px solid #EBEBEB;
		display: flex;
		align-items: center;
		padding-right: 20px;

		/* margin-bottom: 24px; */
		.invite-button {
			padding: 6px 16px;
			background:  @srims-primary-color;
			color: white;
			margin-left: 24px;
			margin-right: 30px;
			border-radius: 4px;
			min-width: 145px;
			cursor: pointer;

			&:hover {
				opacity: 0.8;
			}
		}

		.option-icon:hover {
			opacity: 0.6;
		}
	}

	.i-content {
		display: flex;
		min-height: calc(100vh - 180px);
		background-color: white;

		.table-content {
			width: 0px;
			flex-grow: 1;
			border-right: 1px solid #eee;

			.search-panal {
				display: flex;
				margin-top: 24px;
				padding-left: 8px;

				.search-options {
					width: 0px;
					flex-grow: 1;
					display: flex;

					.search-option {
						width: 0px;
						flex-grow: 1;
					}

					.search-option+.search-option {
						margin-left: 16px;
					}
				}

				.search-operation {
					white-space: nowrap;
					margin-left: 8px;
					margin-right: 8px;


					.reset-btn {
						background: #eeeeee;
					}

					.ant-btn+.ant-btn {
						margin-left: 8px;
					}
				}
			}

			.multi-operation-panal {
				margin-top: 16px;
				text-align: left;
				padding-left: 10px;
			}

			.table-ctn {
				min-height: calc(100vh - 340px);
				margin-top: 16px;

				.open-link {
					color: #FF4D4F;

					&:hover {
						opacity: 0.8;
					}
				}

				.statu-btn {
					&:hover {
						opacity: 0.8;
					}
				}

				/deep/ .ant-table-thead {
					background: #F0F5FF;
				}

				/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
					margin: 0px;
				}

				/deep/ .ant-table-row {
					td {
						padding: 7px;
					}
				}
			}

			.operations {
				//padding: 16px;
				display: flex;

				.icon1 {
					font-size: 18px;
					cursor: pointer;
					fill: #4E5969;
					color: #4E5969;
				}

				.icon1+.icon1 {
					margin-left: 16px;
				}
			}
		}

		.mesaage-content {
			width: 273px;
			//border-left: 1px solid #EBEBEB;
		}
	}

	.default-pagination {
		text-align: center;
		margin-bottom: 24px;
		margin-top: 20px;

	}
}
</style>

<style lang="less" scoped>
/deep/ .ant-popover-inner-content {
	padding: 8px 0px;
}

.option {
	padding: 8px 16px 8px;
	cursor: pointer;
	width: 116px;

	&:hover {
		background: #EEEEEE;
		transition: .2s background ease-in-out;
		border-radius: 2px;
	}
}</style>

