<template>
    <a-modal :width="600" :visible.sync="visible" @cancel="handleCancel" title="编辑审核权限">
    <a-spin :spinning="loading" tip="正在加载..." :delay="300">
        <a-form-model ref="formRef" :model="formData" style="width: 95%;margin: 0 auto;" :colon="true" :labelCol="{
            xs: { span: 24 },
            sm: { span: 6 },
        }" :wrapper-col="{ span: 18 }" :rules="rules">
            <a-form-model-item hasFeedback label="审核员" prop="userid">
                <a-select v-model="formData.userid" placeholder="请选择">
                    <a-select-option v-for="(user, index) in projectHeadInfo" :key="index" :value="user.userId" >
                    {{ user.name }}({{ user.email }})
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item label="当前审核员">
                <div>
                    {{ getCurrentAuditors }}
                </div>
            </a-form-model-item> -->
        </a-form-model>
    </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button :disabled="loading || isSame" type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { computed, ref, watch } from 'vue-demi';
import { getProjectAudit, setAssistant } from '../../../api/projectService';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        projectId: {
            type: String | null,
            required: true
        }
    },
    setup(props, context) {
        // 表单实例
        const formRef = ref(null);
        // 表单数据
        const formData = ref({
            userid: undefined
        })
        // 校验规则
        const rules = ref({
            userid: {
                required: true,
                message: '请选择'
            }
        })
        // 取消操作
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 加载状态
        const loading = ref(false);
        // 确认操作
        const handleConfirm = () => {
            formRef.value && formRef.value.validate( async (valid) => {
                if(valid) {
                    const res = await setAssistant({
                        projectid: props.projectId,
                        userid: formData.value.userid
                    });
                    if(res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('update:visible', false);
                    }
                }
            })
        }
        // 当前审核员数据
        const nowauditinfo = ref([]);
        // 全部审核员
        const projectHeadInfo = ref([]);
        // 当前审核员字符串
        const getCurrentAuditors = computed(() => {
            const temp = [];
            nowauditinfo.value.forEach(Element => {
                temp.push(`${Element.name}(${Element.email})`)
            })
            return temp.join('、')
        })
        // 是否和当前审核员一致
        const isSame = computed(() => {
            if(!nowauditinfo.value.length) {
                return false;
            }
            else {
                return nowauditinfo.value[0].userId === formData.value.userid;
            }
        })
        // 初始化
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                loading.value = true;
                formRef.value && formRef.value.resetFields();
                const res = await getProjectAudit({
                    projectid: props.projectId
                });
                if(res.code === 200) {
                    if( res.data.nowauditinfo.length > 0) {
                        nowauditinfo.value = res.data.nowauditinfo;
                        formData.value.userid = res.data.nowauditinfo[0].userId;
                    }
                    (res.data.nowauditinfo) && (projectHeadInfo.value = [...res.data.progectheadinfo, ...res.data.nowauditinfo] );
                    
                    loading.value = false;
                }
            }
        })
        return {
            formRef,
            formData,
            rules,
            handleCancel,
            handleConfirm,
            loading,
            projectHeadInfo,
            nowauditinfo,
            getCurrentAuditors,
            isSame
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    .concel-btn {
        background: #eeeeee;
    }
}
</style>